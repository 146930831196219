import iconOne from "@data/images/icons/icon-07-60x60.png";
import iconTwo from "@data/images/icons/icon-08-60x60.png";
import iconThree from "@data/images/icons/icon-09-60x60.png";
import iconFour from "@data/images/icons/icon-10-60x60.png";

export const data = [
	{
		id: "box-icon-3-1",
		title: "Quality Assurance System",
		desc: "Our service offerings enhance customer experience throughout secure & highly functional end-to-end warranty management.",
		path: "/",
		icon: iconOne
	},
	{
		id: "box-icon-3-2",
		title: "Accurate Testing Processes",
		desc: "Develop and propose product improvements through periodical and accurate testing, repairing & refining every version.",
		path: "/",
		icon: iconTwo
	},
	{
		id: "box-icon-3-3",
		title: "Smart API Generation",
		desc: "Develop and propose product improvements through periodical and accurate testing, repairing & refining every version.        ",
		path: "/",
		icon: iconThree
	},
	{
		id: "box-icon-3-4",
		title: "Infrastructure Integration Technology",
		desc: "At Mitech, we have a holistic and integrated approach towards core modernization to experience technological evolution.        ",
		path: "/",
		icon: iconFour
	}
]